export const formatPhoneNumber = (input?: string | number): string => {
  if (typeof input !== 'string' && typeof input !== 'number') return '';

  // Remove the leading '1' country code if it's an 11-digit number
  if (String(input).length === 11 && String(input).startsWith('1')) {
    input = String(input).substring(1);
  }

  // Convert to string, strip all non-digit characters, and trim to ten characters
  const cleaned = String(input).replace(/\D/g, '').substring(0, 10);
  const len = cleaned.length;

  if (len === 0) return '';

  if (len < 4) return `(${cleaned}`;
  if (len < 7) return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}`;

  return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
};
